<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="6">
            <b-card>
                <b-form @submit.prevent="update">
                  <b-row>
                    <!-- name -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Title')"
                        label-for="v-name"
                      >
                        <b-form-input
                          id="v-name"
                          :placeholder="$t('Title')"
                          v-model="thePackage.name"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- name_en -->
                    <!-- <b-col cols="12">
                      <b-form-group
                        :label="$t('Title En')"
                        label-for="v-name_en"
                      >
                          <b-form-input
                            id="v-name_en"
                            :placeholder="$t('Title En')"
                            v-model="package.name_en"
                          />
                      </b-form-group>
                    </b-col> -->

                    <!-- description -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Description')"
                        label-for="v-description"
                      >
                          <b-form-textarea
                            id="v-description"
                            :placeholder="$t('Description')"
                            v-model="thePackage.description"
                          />
                      </b-form-group>
                    </b-col>
                    <!-- description_en -->
                    <!-- <b-col cols="12">
                      <b-form-group
                        :label="$t('Description En')"
                        label-for="v-description_en"
                      >
                          <b-form-textarea
                            id="v-description_en"
                            :placeholder="$t('Description En')"
                            v-model="package.description_en"
                          />
                      </b-form-group>
                    </b-col> -->

                    <!-- Image -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Image')"
                        label-for="v-image"
                      >
                        <b-img :src="thePackage.thumbnail" v-if="thePackage.thumbnail" width="120" thumbnail class="mb-1" />
                        <b-form-file
                          id="v-image"
                          accept="image/jpeg, image/jpg, image/png, image/gif"
                          :browse-text="$t('Browser')"
                          :placeholder="$t('Upload Image')"
                          v-model="thePackage.new_image"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- products -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Products')"
                        label-for="v-products"
                      >
                      <v-select
                        v-model="thePackage.into_packages"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="products"
                        multiple
                        class="mb-1"
                      />
                      <template v-for="(product, i) in thePackage.into_packages">
                        <div :key="i" class="mb-1">
                          <b-row>
                            <b-col cols="4">
                              {{product.theName}}
                            </b-col>
                            <b-col cols="8">
                              <b-form-group
                              :label="$t('Quantity')"
                              >
                                <b-form-input
                                  v-model="product.into_package_quantity"
                                  type="number"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </template>
                      <p class="text-primary">{{$t('Products price')}}: {{productsPrice}} {{$t('SAR')}}</p>
                      </b-form-group>
                    </b-col>

                    <!-- price -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Package Price')"
                        label-for="v-price"
                      >
                            <b-form-input
                            id="v-price"
                            :placeholder="$t('Package Price')"
                            v-model="thePackage.price"
                            />
                      </b-form-group>
                    </b-col>

                    <!-- cost_price -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Cost Price')"
                        label-for="v-cost_price"
                      >
                          <b-form-input
                            id="v-cost_price"
                            :placeholder="$t('Cost Price')"
                            v-model="thePackage.cost_price"
                          />
                      </b-form-group>
                    </b-col>

                    <!-- quantity -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Quantity')"
                        label-for="v-quantity"
                      >
                          <b-form-input
                            id="v-quantity"
                            :placeholder="$t('Quantity')"
                            v-model="thePackage.quantity"
                          />
                      </b-form-group>
                    </b-col>

                    <!-- availability_date -->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Availability Date')"
                        label-for="v-availability_date"
                      >
                          <b-form-datepicker
                            v-model="thePackage.availability_date"
                            d="v-availability_date"
                            :placeholder="$t('Availability Date')"
                          />
                      </b-form-group>
                    </b-col>

                    <!-- Is Available -->
                    <b-col cols="6">
                      <b-form-group
                        :label="$t('Is Active ?')"
                        label-for="v-availability"
                      >
                        <b-form-checkbox
                          id="v-availability"
                          :checked="thePackage.availability == 1 ? true : false"
                          class="custom-control-primary"
                          @change="changeAvailable"
                          name="availability"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <!-- submit -->
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                      >
                        <template v-if="!isLoadingSave">
                          {{$t('Save')}}
                        </template>
                        <template v-if="isLoadingSave">
                          {{$t('Saveing')}}...
                          <b-spinner small />
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'PackageUpdate',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      products: [],
      thePackage:{},
    }
  },
  computed: {
    productsPrice() {
      if (Object.keys(this.thePackage).length && Object.keys(this.thePackage.into_packages).length) {
        let price = 0;
        this.thePackage.into_packages.forEach(product => {
          price += Number(product.original_price) * product.into_package_quantity
        });
        return price;
      }
      return 0;
    }
  },
  mounted() {
    this.getPackage();
    this.getProducts();
  },
  methods: {
    update() {
      this.isLoadingSave = true;

      // create form data to upload images
      let form = new FormData(); 
      Object.entries(this.thePackage).forEach(([key, value]) => {
        if (key == 'into_packages') {
          form.append(key, JSON.stringify(value))
        } else {
          form.append(key, value);
        }
      });

      useJwt.post('/packages/update',form)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        this.isLoadingSave = false
        let error_msg = ''
        if(error.response.data.message.product_id != undefined) {
            error_msg+=error.response.data.message.product_id
        }
        this.$root.showAlert('error', error_msg)
      });
    },
    getProducts(){
      useJwt.get('/products/get-all')
      .then((response) => {
        //  console.log(response.data)
        this.products = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
    changeAvailable(){
      if(this.thePackage.availability == 0) {
        this.thePackage.availability = 1
      } else {
        this.thePackage.availability = 0
      }
    },
    getPackage(){
        this.isLoading = true
        useJwt.post('/packages/get-by-id',{id: this.$route.params.id})
        .then((response) => {
          this.thePackage = response.data.data
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
  }
}
</script>
